import React, { useState } from 'react';
import { StyledEngineProvider } from '@mui/material/styles';
import ParentInfo from './Step1/ParentInfo';
import ChildrenInfo from './Step2/ChildrenInfo';
import Preview from './Step3/Preview';
import ParentFormClosed from './ParentFormClosed';
import { useSelector } from 'react-redux';
import { selectConfigByKey } from '../../../custom-slices/configurationSlice';

const API_URL = process.env.REACT_APP_API_URL;
const testingURLArray = ['http://localhost:5500', 'https://angeltree-server-test.ufvsus.ca'];

const ParentForm = () => {
  const configsState = useSelector(state => state.configuration.configs);

  const angelTreeTimeline = selectConfigByKey(configsState, 'angel-tree-timeline');

  const openOnProduction = new Date().getTime() >= new Date(angelTreeTimeline?.registration?.startDate).getTime() && new Date().getTime() <= new Date(angelTreeTimeline?.registration?.endDate).getTime() ? 0 : -1;
  const [step, setStep] = useState(testingURLArray.includes(API_URL) ? 0 : openOnProduction);

  const nextStep = () => {
    setStep((prev) => prev + 1);
  };

  const prevStep = () => {
    setStep((prev) => prev - 1);
  };

  switch (step) {
    case 0:
      return (
        <StyledEngineProvider injectFirst>
          <ParentInfo step={step} nextStep={nextStep} />
        </StyledEngineProvider>
      );

    case 1:
      return (
        <StyledEngineProvider injectFirst>
          <ChildrenInfo step={step} prevStep={prevStep} nextStep={nextStep} />
        </StyledEngineProvider>
      );

    case 2:
      return (
        <StyledEngineProvider injectFirst>
          <Preview
            step={step}
            prevStep={prevStep}
            nextStep={nextStep}
            setStep={setStep}
          />
        </StyledEngineProvider>
      );
    default:
      return (
        <StyledEngineProvider injectFirst>
          <ParentFormClosed angelTreeTimeline={angelTreeTimeline} />
        </StyledEngineProvider>
      );
  }
};

export default ParentForm;
