import React from 'react';
import { Container, Box, Typography } from '@mui/material';
import styles from './ParentForm.module.css';
import { StyledEngineProvider } from '@mui/material/styles';
import HeaderCard from '../../layout/customs/header/HeaderCard';
import BorderColorIcon from '@mui/icons-material/BorderColor';

const ParentFormContainer = (props) => {
  return (
    <StyledEngineProvider injectFirst>
      <Container>
        <HeaderCard
          title='ANGEL APPLICATION FORM'
          icon={<BorderColorIcon sx={{ fontSize: 60 }} />}
        />
        <Box className={styles.mainBox}>
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant='body1' component='p'>
              Angel submissions are open to UFV students with active student numbers and children under 16 are eligible and encouraged to fill out the application below. If you are not sure if you fall within the criteria for the Angel Tree, we encourage you to apply.
            </Typography>
          </Box>

          {props.children}
        </Box>
      </Container>
    </StyledEngineProvider>
  );
};

export default ParentFormContainer;
