import React, { useState, useEffect } from 'react';

// Import components
import {
  Box,
  Typography,
  MenuItem,
  FormControl,
  FormHelperText,
  InputLabel,
  Button,
  Stack,
  IconButton,
  Divider,
  Chip,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import HelpIcon from '@mui/icons-material/Help';

import GlassCard from '../../../customs/GlassCard';
import { CustomTextField } from '../../../customs/CustomTextField';
import { CustomSelectField } from '../../../customs/CustomSelectField';

import FormContainer from '../../../customs/FormContainer';
import ParentFormContainer from '../ParentFormContainer';
import CustomStepper from '../CustomStepper';

// Import React-Hook-Forms and yup
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { toast } from 'react-toastify';

// Import useSelector and useDispatch to manage the global state
import { useSelector, useDispatch } from 'react-redux';
// Import parent reducers
import { setParentFormValues } from '../../parentFormSlice';
import FAQDialog from '../FAQDialog';

const schema = yup.object().shape({
  children: yup.array().of(
    yup.object().shape({
      name: yup
        .string()
        .matches(/^(\D*)$/, 'Name should not container number')
        .required('First name Required'),
      age: yup.string().required('Please select age'),
      gender: yup.string().required('Please select gender'),
      wish: yup.string().required('Angle wish required'),
    })
  ),
});

const wishTips = [
  {
    id: '1',
    content: 'Total wishes should range between $50 - $75 per Angel.'
  },
  {
    id: '2',
    content: 'When submitting multiple wishes, please use a comma(,) to separate the wishes, similar to the placeholder example.'
  },
  {
    id: '3',
    content: 'Please provide specific details such as desired size, colour, etc.'
  }
];

const ChildrenInfo = ({ step, prevStep, nextStep }) => {
  const [open, setOpen] = useState(false);

  const formValues = useSelector((state) => state.parent.parentForm.values);
  const dispatch = useDispatch();

  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      children: [{ name: '', age: '', gender: '', wish: '' }],
    },
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'children',
  });

  const childrenArray = watch('children');

  useEffect(() => {
    if (
      formValues.hasOwnProperty('children') &&
      formValues.children.length > 0
    ) {
      setValue('children', formValues.children);
    }
  }, [formValues, setValue]);

  useEffect(() => {
    if (childrenArray.length === 6) {
      toast.info(
        'Please get in touch with us directly at angeltree@ufvsus.ca to register more than six Angels.'
      );
    }
  }, [childrenArray]);

  const goBack = () => {
    prevStep();
  };

  const goNext = (data) => {
    dispatch(setParentFormValues(data));
    nextStep();
  };

  return (
    <ParentFormContainer>
      <CustomStepper step={step} />
      <GlassCard sx={{ marginTop: 5, width: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography sx={{ alignSelf: 'center' }} variant='h5' component='h5'>
            Angel's information
          </Typography>
          <IconButton
            color='secondary'
            aria-label='delete'
            sx={{ fontSize: '30px' }}
            onClick={() => setOpen((prev) => !prev)}
          >
            <HelpIcon fontSize='inherit' />
          </IconButton>
        </Box>

        <FormContainer>
          <form onSubmit={handleSubmit(goNext)} noValidate>
            {fields.map((item, index) => (
              <Box key={item.id}>
                {index > 0 ? (
                  <>
                    <Divider>
                      <Chip
                        label={`Angel ${index + 1}`}
                        color='primary'
                        icon={<FaceRetouchingNaturalIcon />}
                        onDelete={() => remove(index)}
                      />
                    </Divider>
                  </>
                ) : (
                  <>
                    <Divider>
                      <Chip
                        label={`Angel ${index + 1}`}
                        color='primary'
                        icon={<FaceRetouchingNaturalIcon />}
                      />
                    </Divider>
                  </>
                )}

                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={{ xs: 4, sm: 4, md: 4 }}
                  justifyContent='flex-start'
                  marginTop='2rem'
                  marginBottom='2rem'
                >
                  <CustomTextField
                    label='First Name'
                    variant='outlined'
                    placeholder='Mary'
                    focusColor='#FDB91B'
                    fullWidth
                    {...register(`children.${index}.name`)}
                    helperText={errors?.children?.[index]?.name?.message}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      gap: 4,
                    }}
                  >
                    <FormControl fullWidth>
                      <InputLabel
                        id={`children.${index}.age-label`}
                        sx={{
                          '&.Mui-focused': {
                            color: '#FDB91B',
                          },
                          color: 'white',
                        }}
                      >
                        Angel's Age
                      </InputLabel>
                      <Controller
                        name={`children.${index}.age`}
                        control={control}
                        render={({ field }) => (
                          <CustomSelectField
                            {...field}
                            label="Angel's Age"
                            labelId={`children.${index}.age-label`}
                            id={`children.${index}.age`}
                          >
                            <MenuItem value='' disabled>
                              <em style={{ color: '#CE4D4D' }}>
                                Please select age
                              </em>
                            </MenuItem>
                            {Array(17)
                              .fill()
                              .map((_, i) => (
                                <MenuItem key={i} value={Number(i)}>
                                  {Number(i)}
                                </MenuItem>
                              ))}
                          </CustomSelectField>
                        )}
                      />
                      {errors?.children?.[index]?.age?.message && (
                        <FormHelperText sx={{ color: 'yellow' }}>
                          {errors?.children?.[index]?.age?.message}
                        </FormHelperText>
                      )}
                    </FormControl>

                    <FormControl fullWidth>
                      <InputLabel
                        id={`children.${index}.gender-label`}
                        sx={{
                          '&.Mui-focused': {
                            color: '#FDB91B',
                          },
                          color: 'white',
                        }}
                      >
                        Gender
                      </InputLabel>
                      <Controller
                        name={`children.${index}.gender`}
                        control={control}
                        defaultValue='2'
                        render={({ field }) => (
                          <CustomSelectField
                            {...field}
                            label='Gender'
                            labelId={`children.${index}.gender-label`}
                            id={`children.${index}.gender`}
                          >
                            <MenuItem value='' disabled>
                              <em style={{ color: '#CE4D4D' }}>
                                Please select a gender
                              </em>
                            </MenuItem>
                            <MenuItem value={'Male'}>Male</MenuItem>
                            <MenuItem value={'Female'}>Female</MenuItem>
                            <MenuItem value={'Other'}>Other</MenuItem>
                          </CustomSelectField>
                        )}
                      />
                      {errors?.children?.[index]?.gender?.message && (
                        <FormHelperText sx={{ color: 'yellow' }}>
                          {errors?.children?.[index]?.gender?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Box>
                </Stack>

                <Box>
                  <CustomTextField
                    label="Angel's wishes"
                    variant='outlined'
                    placeholder='Ex: Purple Winter Gloves Size: Small (4-6yrs), LOL Surprise Confetti Pop Birthday'
                    focusColor='#FDB91B'
                    fullWidth
                    rows={2}
                    multiline
                    {...register(`children.${index}.wish`)}
                    helperText={errors?.children?.[index]?.wish?.message}
                  />

                  <Box component='div' sx={{ marginBottom: '2rem' }}>
                    <Typography
                      variant='caption'
                      component='p'
                      sx={{ marginTop: 1 }}
                    >
                      Tips:
                    </Typography>

                    {wishTips.map(tip => <Typography key={tip.id} variant='caption' component='p'>{tip.id}. {tip.content}</Typography>)}
                  </Box>

                </Box>
              </Box>
            ))}

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '2rem',
              }}
            >
              <IconButton
                aria-label='add'
                color='inherit'
                size='large'
                disabled={childrenArray.length >= 6 ? true : false}
                onClick={() =>
                  append({ name: '', age: '', gender: '', wish: '' })
                }
              >
                <AddCircleIcon fontSize='inherit' />
              </IconButton>
              <Typography variant='caption' component='p'>
                Add another angel
              </Typography>
            </Box>

            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 4, sm: 4, md: 4 }}
              justifyContent='space-between'
              marginTop='2rem'
            >
              <Button
                color='secondary'
                size='large'
                variant='outlined'
                onClick={goBack}
              >
                Previous
              </Button>
              <Button
                type='submit'
                color='secondary'
                size='large'
                variant='contained'
              >
                Next
              </Button>
            </Stack>
          </form>
        </FormContainer>
      </GlassCard>

      <FAQDialog open={open} setOpen={setOpen} />
    </ParentFormContainer>
  );
};

export default ChildrenInfo;
