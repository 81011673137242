import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialConfigurationState = {
    configs: [
        {
            key: "angel-tree-timeline",
            value: {
                registration: {
                    startDate: '11/01/2023',
                    endDate: '11/10/2023'
                },
                sponsorship: {
                    startDate: '11/10/2023',
                    endDate: '12/04/2023'
                },
                dropoff: {
                    startDate: '11/14/2023',
                    endDate: '12/04/2023'
                },
                pickup: {
                    startDate: '12/12/2023',
                    endDate: '12/13/2023'
                }
            },
        },
        {
            key: 'enable-qr-code-after-form-submission',
            value: false
        }
    ],
    loading: false,
    success: false,
    error: null,
};

const configurationSlice = createSlice({
    name: 'configuration',
    initialState: initialConfigurationState,
    reducers: {},
},
);

const configsState = (configs) => configs;
const configKey = (state, key) => key;

export const selectConfigByKey = createSelector([configsState, configKey], (configs, key) => configs.length > 0 ? configs.find(config => config.key === key).value : null);

export default configurationSlice.reducer;
